
import instagram from '../assets/instagram.png'
import linkedin from '../assets/linkedin.jpg'
import youtube from '../assets/youtube.jpeg'
import github from '../assets/github.png'
import telegram from '../assets/telegram.png'
import spotify from '../assets/Spotify.png'
import X from '../assets/X.png'
import snapchat from '../assets/snapchat.png'
import Mail from '../assets/mail.jpeg'
import discord from '../assets/Discord.webp'
import portfolio from '../assets/portfolio.png'
import Resume from '../assets/Resume.jpg'

import { ItemType } from '../ts';

const items: ItemType[] = [
    {
        "title": "Portfolio",
        "subtitle": "@Gm_Abhishek |  My Portfolio🤩",
        "image": portfolio,
        "link": "https://gmabhishek-portfolio.vercel.app/" //Github Profile link
    },
{
    "title": "GitHub",
    "subtitle": "@Gm_Abhishek | 🏡 of my works ",
    "image": github,
    "link": "https://github.com/Gmabhishek" //Github Profile link
},
{
    "title": "Instagram",
    "subtitle": "@Gm_Abhishek |  Shots of my life 🙂",
    "image": instagram,
    "link": "https://www.instagram.com/g.m_a.b.h.i.s.h.e.k/" //instagram profile link 
},
{
    "title": "X",
    "subtitle": "@Gm_Abhishek_ | X🚀",
    "image": X,
    "link": "https://X.com/Gm_Abhishek_"// twitter profile link 
},
{
    "title": "LinkedIn",
    "subtitle": "@Gm_Abhishek | Just for Profession 👨‍💼",
    "image": linkedin,
    "link": "https://www.linkedin.com/in/gm-abhishek-a39348224/" // linkedin
},

{
    "title": "Spotify",
    "subtitle": "@Gm_Abhishek | Tune in W me🎶",
    "image": spotify,
    "link": "https://open.spotify.com/user/31tb2rg3vnytlj3g4rujpcldwrry?si=d3cb4b17735545d7" 
},

{
    "title": "Telegram",
    "subtitle": "@Gm_Abhishek | Ping me🔔",
    "image": telegram,
    "link": "https://telegram.me/Gm_Abhishek" 
},
{
    "title":"Discord",
    "subtitle": "@Gm_Abhishek | Tap in to the convo🎧",
    "image": discord,
    "link": "https://discord.gg/beFxXdA3t2" 
},
{
    "title": "Snapchat",
    "subtitle": "@Gm_Abhishek | Click it 📸 ,I get it",
    "image": snapchat,
    "link": "https://www.snapchat.com/add/gm_abhishek?sender_web_id=9a505cdd-cb38-45cf-b902-a9f93f4cabce&device_type=desktop&is_copy_url=true" //Telegram Pofile 
},
{
    "title": "YouTube",
    "subtitle": "@Gm_Abhishek | Check out my clips🎬",
    "image": youtube,
    "link": "https://youtube.com/@gm_abhishek"
}
,{
    "title": "Mail",
    "subtitle": "@Gm_Abhishek | Reach out to me ✉️",
    "image": Mail,
    "link": "mailto:gmabhishek2004@gmail.com" //Telegram Pofile 
},
{
    "title": "Resume",
    "subtitle": "@Gm_Abhishek | That's me 👨🏻‍🎓",
    "image": Resume,
    "link": "https://www.gmabhishek.me" //Telegram Pofile 
}
]


export { items}


